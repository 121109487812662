import React, { FC, useEffect, useRef } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { elb } from '@elbwalker/walker.js';
import { Theme } from '@sumup/design-tokens';

import * as ColumnLayoutService from '../../ColumnLayoutService';
import Column from '../Column';
import {
  Column as ColumnType,
  Alignments,
  ImagePreset,
} from '../../interfaces';

import dataSelector from '~/shared/util/data-selector';
import { ALIGNMENT } from '~/shared/constants';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { DarkModeProvider } from '~/shared/providers/DarkModeContext';

export const COMBOS = {
  BLACK_BG: 'Black Background',
  BLUE_BG: 'Blue Background',
  GREY_BG: 'Grey Background',
  WHITE_BG_BLACK_BORDER: 'White Background and Black Border',
  WHITE_BG_GRAY_BORDER: 'White Background and Gray Border',
};

export const COMBOS_STYLE_MAP = {
  [COMBOS.BLACK_BG]: () => css`
    background-color: var(--cui-bg-normal);
    color: var(--cui-fg-normal);
  `,
  [COMBOS.BLUE_BG]: () => css`
    background-color: var(--cui-bg-accent);
  `,
  [COMBOS.GREY_BG]: () => css`
    background-color: var(--cui-bg-subtle);
  `,
  [COMBOS.WHITE_BG_BLACK_BORDER]: (theme: Theme) => css`
    background-color: var(--cui-bg-normal);
    border: ${theme.borderWidth.mega} solid var(--cui-border-strong);
  `,
  [COMBOS.WHITE_BG_GRAY_BORDER]: (theme: Theme) => css`
    background-color: var(--cui-bg-normal);
    border: ${theme.borderWidth.mega} solid var(--cui-border-normal);
  `,
};

const DARK_MODE_COLOR_SCHEME = 'dark';

const layoutContainerStyles = ({
  theme,
  numberOfColumns,
  stackOnTablet,
  hasChildColumnsColors,
  layoutBackgroundColorsCombo,
}: {
  theme?: Theme;
  numberOfColumns?: number;
  stackOnTablet?: boolean;
  hasChildColumnsColors?: boolean;
  layoutBackgroundColorsCombo?: string;
}) => css`
  // reset clearfix because of Safari bug
  // https://stackoverflow.com/questions/34250282/flexbox-wraps-last-column-of-the-first-row-in-safari
  &::before,
  &::after {
    content: normal;
  }

  display: grid;

  ${theme.mq.kilo} {
    ${!stackOnTablet && 'grid-template-columns: repeat(2, minmax(0, 1fr));'}
  }

  ${theme.mq.mega} {
    grid-template-columns: repeat(${numberOfColumns}, minmax(0, 1fr));
  }

  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ${hasChildColumnsColors &&
  css`
    grid-gap: ${theme.spacings.tera};
  `}

  ${COMBOS_STYLE_MAP[layoutBackgroundColorsCombo]?.(theme)}
  ${layoutBackgroundColorsCombo &&
  css`
    grid-gap: 0px;
    border-radius: ${theme.borderRadius.mega};
  `}
`;
const LayoutContainer = styled('div')(layoutContainerStyles);

const columnStyles = ({
  theme,
  numberOfColumns,
  hasSplitLayout,
  splitLayoutHorizontalImageAlignment,
  hasParentLayoutColors,
  backgroundColorsCombo,
}: {
  theme?: Theme;
  numberOfColumns?: number;
  hasSplitLayout?: boolean;
  splitLayoutHorizontalImageAlignment?: string;
  hasParentLayoutColors?: boolean;
  backgroundColorsCombo?: string;
}) => {
  const spacing =
    numberOfColumns > 3 ? theme.spacings.tera : theme.spacings.peta;

  return css`
    padding-left: ${theme.spacings.kilo};
    padding-right: ${theme.spacings.kilo};

    ${theme.mq.kilo} {
      padding-left: calc(${spacing} / 2);
      padding-right: calc(${spacing} / 2);
    }

    ${hasSplitLayout &&
    css`
      padding-top: calc(${theme.spacings.tera} / 2);
      padding-bottom: calc(${theme.spacings.tera} / 2);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: ${splitLayoutHorizontalImageAlignment === ALIGNMENT.RIGHT
        ? 'row-reverse'
        : 'row'};
    `}

    ${!hasParentLayoutColors &&
    css`
      ${COMBOS_STYLE_MAP[backgroundColorsCombo]?.(theme)}
      ${backgroundColorsCombo &&
      css`
        border-radius: ${theme.borderRadius.mega};
      `}
    `}
  `;
};

const LayoutColumn = styled('div')(columnStyles);

export interface ColumnLayoutStackProps {
  columns?: ColumnType[];
  index?: number;
  alignments?: Alignments;
  fullWidthButtons?: boolean;
  alignAllButtons?: boolean;
  stackOnTablet?: boolean;
  imagePreset?: ImagePreset;
  layoutBackgroundColorsCombo?: string;
}

export const ColumnLayoutStack: FC<ColumnLayoutStackProps> = ({
  columns,
  alignments,
  fullWidthButtons,
  alignAllButtons,
  index,
  stackOnTablet,
  imagePreset,
  layoutBackgroundColorsCombo,
}) => {
  const columnRef = useRef(null);
  const viewportName = useViewportName();

  useEffect(() => {
    if (columnRef.current) {
      elb('walker init', columnRef.current);
    }
  }, [columnRef]);

  if (isEmpty(columns)) {
    return null;
  }

  const numberOfColumns = columns.length;
  const hasParentLayoutColors = !!layoutBackgroundColorsCombo;
  const hasChildColumnsColors = !!columns.find(
    (column) => !!column.backgroundColorsCombo,
  );

  const isLayoutDarkMode = layoutBackgroundColorsCombo === COMBOS.BLACK_BG;

  return (
    <LayoutContainer
      data-selector={dataSelector('columns', 'columnLayout')}
      ref={columnRef}
      numberOfColumns={numberOfColumns}
      stackOnTablet={stackOnTablet}
      layoutBackgroundColorsCombo={layoutBackgroundColorsCombo}
      hasChildColumnsColors={hasChildColumnsColors}
      data-color-scheme={isLayoutDarkMode ? DARK_MODE_COLOR_SCHEME : undefined}
    >
      <DarkModeProvider value={isLayoutDarkMode}>
        {columns.map((column, i) => {
          const hasSplitLayout = ColumnLayoutService.hasSplitLayoutForViewport(
            viewportName,
            column,
          );

          const isColumnDarkMode =
            column.backgroundColorsCombo === COMBOS.BLACK_BG;

          return (
            <LayoutColumn
              key={`${index}-${i}`}
              hasSplitLayout={hasSplitLayout}
              splitLayoutHorizontalImageAlignment={
                column?.splitLayoutHorizontalImageAlignment || ALIGNMENT.LEFT
              }
              numberOfColumns={numberOfColumns}
              backgroundColorsCombo={column.backgroundColorsCombo}
              hasParentLayoutColors={hasParentLayoutColors}
              data-color-scheme={
                isColumnDarkMode ? DARK_MODE_COLOR_SCHEME : undefined
              }
            >
              <DarkModeProvider value={isColumnDarkMode}>
                <Column
                  column={column}
                  alignments={alignments}
                  fullWidthButtons={fullWidthButtons}
                  alignAllButtons={alignAllButtons}
                  index={i}
                  imagePreset={imagePreset}
                  numberOfColumns={numberOfColumns}
                />
              </DarkModeProvider>
            </LayoutColumn>
          );
        })}
      </DarkModeProvider>
    </LayoutContainer>
  );
};
